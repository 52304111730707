import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { NavLink } from "react-router-dom";

export const getOptions = () => {
  return {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (entry) => {
        return (
          <NavLink to={`/${entry.data.target.fields.slug}`}>
            {entry.content[0].value}
          </NavLink>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (asset) => {
        return (
          <a
            href={asset.data.target.fields.file.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {asset.content[0].value}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (asset) => {
        if (asset.data.target.fields.file.contentType.includes("image")) {
          return (
            <img
              src={asset.data.target.fields.file.url}
              alt={asset.data.target.fields.title}
            />
          );
        }

        return <></>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (entry) => {
        if (entry.nodeType === "embedded-entry-block") {
          return (
            <NavLink to={`/${entry.data.target.fields.slug}`}>
              {entry.data.target.fields.title}
            </NavLink>
          );
        }

        return <></>;
      },
    },
  };
};
