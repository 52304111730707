import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    html {
        overflow-x: hidden;
        min-height: 100%;
        position: relative;
    }

    body {
        overflow-x: hidden;
        height: 100%;
        color: #676767;
        font-family: 'Raleway', Arial, sans-serif;
        background-color: #212121;
        margin: 0;
        padding: 0;
    }

    h1 {
        text-align: center;
    }

    h2 {
        color: #000000;
        width: 100%;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 2px dotted #ff5c5c;
        text-align: center;
    }

    h3 {
        color: #655a5e;
    }

    *:focus {
        outline: none;
    }

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: absolute;
        width: 36px;
        height: 30px;
        left: 36px;
        top: 42px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #00baf2;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: white;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #ffffff;
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #0682aa;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #0682aa;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
        margin-left: 13px;
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
        width: 100%;
        padding-bottom: 25px;
        text-decoration: none;
        color: white;
        font-weight: bold;
        font-size: 24px;

        &:hover {
            color: #212121;
        }
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
`;

export default GlobalStyles;
