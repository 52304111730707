import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getOptions } from "../../helpers/contentful";
import { Helmet } from "react-helmet";

const Text = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
`;

const Content = styled.div`
  a,
  a:visited {
    color: #007ba3;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
  }
`;

const Ingredients = styled.div`
  padding: 20px;
  background-color: #017ba3;
  color: white;
  grid-area: ing;
  margin-top: 20px;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    margin-top: 0;
  }

  h2 {
    text-align: left;
    color: white;
    margin-top: 0;
    border-bottom: 1px solid #03b9f2;
  }
`;

const Top = styled.div`
  display: grid;
  margin-top: 20px;

  grid-template-areas:
    "image"
    "ing";

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    grid-template-columns: 300px auto;
    grid-template-areas: "image ing";
  }
`;

const Image = styled.img`
  grid-area: image;
  max-width: 400px;
  margin: 0 auto;

  @media ${(props) => props.theme.bootstrapBreakpoints.xSmall} {
    max-width: 100%;
  }

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    max-width: 300px;
    margin: 0;
  }
`;

const Recipe = () => {
  const [content, setContent] = useState(null);
  const { id } = useParams();
  console.log("🚀 ~ file: Recpie.jsx ~ line 31 ~ Recipe ~ id", id);

  useEffect(() => {
    const getContent = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields"],
        content_type: "recipe",
        "fields.slug[in]": id,
      });

      if (response.items && response.items.length > 0)
        setContent(response.items[0].fields);
    };

    getContent();
  }, [id]);

  if (!content) return <></>;

  return (
    <>
      <Helmet>
        <title>{content.name} Recipe - Oyster Boys Brewing Co</title>
      </Helmet>
      <Text>{content.name}</Text>
      <Top>
        <Image src={content.image.fields.file.url} alt={content.name} />
        <Ingredients>
          <h2>Ingredients</h2>
          {documentToReactComponents(content.ingredients, getOptions())}
        </Ingredients>
      </Top>
      <Content>
        {documentToReactComponents(content.information, getOptions())}
      </Content>
    </>
  );
};

export default Recipe;
