import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./components/styles/globalStyles.jsx";
import Container from "./components/Container";
import Header from "./components/Header";
import { elastic as Menu } from "react-burger-menu";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserFriends,
  faBeer,
  faStarHalfAlt,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import Footer from "./components/Footer";
import { BrowserRouter, Switch, Route, NavLink } from "react-router-dom";

import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import Merch from "./components/pages/Merch";
import Recipes from "./components/pages/Recipes";
import Scoreboard from "./components/pages/Scoreboard";
import Videos from "./components/pages/Videos";
import Recipe from "./components/pages/Recpie.jsx";

const StyledMenu = styled.div`
  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    display: none;
  }

  a {
    svg {
      margin-right: 10px;
    }
  }
`;

const Body = styled.div`
  padding: 20px;
`;

const theme = {
  bootstrapBreakpoints: {
    xSmall: "(min-width: 370px)",
    small: "(min-width: 576px)",
    medium: "(min-width: 768px)",
    large: "(min-width: 992px)",
    extraLarge: "(min-width: 1200px)",
  },
};

function App() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GlobalStyles />
          <StyledMenu>
            <Menu
              isOpen={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
            >
              <NavLink to="/" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faHome} fixedWidth />
                Home
              </NavLink>
              <NavLink to="/about" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faUserFriends} fixedWidth />
                About Us
              </NavLink>
              <NavLink to="/scoreboard" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faStarHalfAlt} fixedWidth />
                Scoreboard
              </NavLink>
              <NavLink to="/recipes" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faBeer} fixedWidth />
                Recipes
              </NavLink>
              <NavLink to="/videos" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faYoutube} fixedWidth />
                Videos
              </NavLink>
              <NavLink to="/merch" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faTags} fixedWidth />
                Merch
              </NavLink>
            </Menu>
          </StyledMenu>
          <Header />
          <Container>
            <Body>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/about" component={AboutUs} />
                <Route path="/scoreboard" component={Scoreboard} />
                <Route exact path="/recipes" component={Recipes} />
                <Route path="/recipes/:id" component={Recipe} />
                <Route path="/videos" component={Videos} />
                <Route path="/merch" component={Merch} />
              </Switch>
            </Body>
          </Container>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
