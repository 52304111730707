import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getOptions } from "../../helpers/contentful";
import { Helmet } from "react-helmet";

const Text = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
`;

const Content = styled.div`
  a,
  a:visited {
    color: #007ba3;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
  }
`;

const AboutUs = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const getContent = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields"],
        content_type: "page",
        "fields.slug[in]": "about-us",
      });

      if (response.items && response.items.length > 0)
        setContent(response.items[0].fields);
    };

    getContent();
  }, []);

  if (!content) return <></>;

  return (
    <>
      <Helmet>
        <title>About Us - Oyster Boys Brewing Co</title>
      </Helmet>
      <Text>{content.title}</Text>
      <Content>
        {documentToReactComponents(content.content, getOptions())}
      </Content>
    </>
  );
};

export default AboutUs;
