import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getOptions } from "../../helpers/contentful";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";

const Text = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
`;

const RecipeList = styled.div`
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-between;
  row-gap: 20px;
  column-gap: 20px;
  box-sizing: border-box;

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }

  .main-link {
    width: 100%;

    @media ${(props) => props.theme.bootstrapBreakpoints.medium} {
      width: 350px;
    }

    @media ${(props) => props.theme.bootstrapBreakpoints.large} {
      width: 300px;
    }
  }
`;

const Content = styled.div`
  a,
  a:visited {
    color: #007ba3;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
  }
`;

const Recipe = styled.div`
  padding: 20px;
  background-color: #017ba3;
  text-align: center;
  margin-bottom: 10px;
  box-sizing: border-box;

  h2 {
    color: white;
    border-bottom: 2px solid #00baf2;
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }
`;

const Button = styled.div`
  text-align: center;
  background-color: #dc2a41;
  padding: 10px;
  border-radius: 20px;
  border: 0;
  font-weight: bold;
  font-size: 16px;
  color: white;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;
  margin-top: 10px;
`;

const CreatedAt = styled.div`
  font-style: italic;
  margin-bottom: 10px;
  color: white;
`;

const Recipes = () => {
  const [content, setContent] = useState(null);
  const [recipies, setRecepies] = useState([]);

  useEffect(() => {
    const getContent = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields"],
        content_type: "page",
        "fields.slug[in]": "recipes",
      });

      if (response.items && response.items.length > 0)
        setContent(response.items[0].fields);
    };

    getContent();
  }, []);

  useEffect(() => {
    const getRecipies = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields", "sys"],
        content_type: "recipe",
        order: "sys.createdAt",
      });

      if (response.items && response.items.length > 0) {
        setRecepies(response.items);
      }
    };

    getRecipies();
  }, []);

  if (!content) return <></>;

  return (
    <>
      <Helmet>
        <title>Recipes - Oyster Boys Brewing Co</title>
      </Helmet>
      <Text>{content.title}</Text>
      <Content>
        {documentToReactComponents(content.content, getOptions())}
      </Content>

      <RecipeList>
        {recipies.map((recipe, i) => (
          <NavLink
            to={`/recipes/${recipe.fields.slug}`}
            key={recipe.fields.name}
            className="main-link"
          >
            <Recipe>
              <h2>{recipe.fields.name}</h2>
              <CreatedAt>
                {dayjs(recipe.sys.createdAt).format("dddd MMMM D, YYYY")}
              </CreatedAt>
              {recipe.fields.image && (
                <img
                  src={recipe.fields.image.fields.file.url}
                  alt={recipe.fields.name}
                />
              )}
              <Button>View the recipe</Button>
            </Recipe>
          </NavLink>
        ))}
      </RecipeList>
    </>
  );
};

export default Recipes;
