import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import axios from "axios";
import { Helmet } from "react-helmet";
import Beer from "../BeerHome";

const StyledHome = styled.div`
  text-align: center;
  iframe {
    width: 100%;

    @media ${(props) => props.theme.bootstrapBreakpoints.large} {
      width: 560px;
    }
  }
`;

const Text = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
`;

const SubText = styled.p`
  color: #007ba3;
  padding: 0;
  margin: 0;
  font-style: italic;
  margin-bottom: 20px;
`;

const AboutText = styled.div`
  text-align: left;

  a,
  a:visited {
    color: #007ba3;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
  }
`;

const Top = styled.div`
  display: grid;
  grid-template-columns: 100%;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
  }
  column-gap: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    margin-bottom: 0;
  }
`;

const Home = () => {
  const [id, setId] = useState(null);
  const [content, setContent] = useState(null);
  const [beer, setBeer] = useState(null);

  useEffect(() => {
    axios
      .get("https://api-oysterboysbrewing-co-uk.onrender.com/v1/videos/")
      .then((result) => {
        setId(result.data.first.id);
      });
  }, []);

  useEffect(() => {
    const client = createClient({
      space: "1ihs72m7oqzb",
      accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
    });

    const getContent = async () => {
      const response = await client.getEntries({
        select: ["fields"],
        content_type: "page",
        "fields.slug[in]": "homepage",
      });

      if (response.items && response.items.length > 0)
        setContent(response.items[0].fields);
    };

    const getBeer = async () => {
      const response = await client.getEntries({
        select: ["fields"],
        content_type: "beer",
      });

      if (response.items && response.items.length > 0) {
        const item =
          response.items[Math.floor(Math.random() * response.items.length)];
        setBeer(item);
      }
    };

    getContent();
    getBeer();
  }, []);

  return (
    <StyledHome>
      <Helmet>
        <title>Oyster Boys Brewing Co</title>
      </Helmet>
      <Top>
        <Section>
          <Text>Our Latest Video</Text>
          <SubText>Remember to smash like!</SubText>
          {id && (
            <iframe
              title="video"
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${id}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </Section>
        <Section>
          {beer && (
            <>
              <Text>Fancy a beer?</Text>
              <SubText>a random beer from our scoreboard to try!</SubText>
              <Beer
                rating={1}
                {...beer.fields}
                showInfoAlways
                noRating
                onHomepage
              />
            </>
          )}
        </Section>
      </Top>
      <AboutText>
        {content && documentToReactComponents(content.content)}
      </AboutText>
    </StyledHome>
  );
};

export default Home;
