import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBeer,
  faEye,
  faChartBar,
  faPoundSign,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { countryCodeEmoji } from "country-code-emoji";
import isoCountries from "../countries";

const StyledBeer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  h2 {
    border: none;
    color: white;
    margin: 0;
    padding-top: 12px;
    width: unset;
  }
`;

const Name = styled.div`
  grid-area: title;

  border-left: 2px solid #267ba3;
  border-right: 2px solid #267ba3;
  background-color: #277ba4;
  padding-bottom: 10px;

  span {
    display: block;
    text-align: center;
    font-style: italic;
    color: #e2e2e2;

    .country {
      display: inline;
      font-style: normal;
    }
  }
`;

const Top = styled.div`
  display: grid;
  grid-template-columns: auto 100px;
  grid-template-areas: "title score";
  width: 100%;
`;

const Score = styled.div`
  padding: 23px 0;
  background-color: ${(props) => props.color};
  color: white;
  font-weight: bold;
  text-align: center;
  grid-area: score;

  span {
    display: block;
    padding-bottom: 5px;
  }
`;

const Info = styled.div`
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px 50px;
  display: none;

  &.visable {
    display: grid;
    animation: fade_in_show 1s;
  }

  @keyframes fade_in_show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media ${(props) => props.theme.bootstrapBreakpoints.xSmall} {
    grid-template-columns: repeat(2, 1fr);
  }

  box-sizing: border-box;
  justify-content: space-between;
`;

const InfoOuter = styled.div`
  border-left: 2px solid #267ba3;
  border-right: 2px solid #267ba3;
`;

const Item = styled.div`
  display: flex;

  svg {
    margin-right: 10px;
    color: #f9bf78;
  }
`;

const TitleScore = styled.div`
  width: 100%;
  color: #f9bf78;
`;

const ExtraInfo = styled.div`
  display: inline-flex;
  margin-bottom: 5px;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    margin-bottom: 10px;
  }
`;

const ItemInner = styled.div`
  display: grid;
`;

const TheInfo = styled.div`
  font-style: italic;
  color: white;
  text-align: left;
`;

const VideoLink = styled.div`
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  border-left: 2px solid #267ba3;
  border-right: 2px solid #267ba3;
  border-bottom: 2px solid #267ba3;
  padding-bottom: 10px;
`;

const Button = styled.a`
  text-align: center;
  background-color: #dc2a41;
  padding: 10px;
  border-radius: 20px;
  border: 0;
  font-weight: bold;
  font-size: 16px;
  color: white;
  box-sizing: border-box;
  display: inline-block;
  text-decoration: none;

  display: none;

  &.visable {
    display: inline-block;
    animation: fade_in_show 1s;
  }

  @keyframes fade_in_show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const BeerHome = ({
  name,
  brewery,
  oysterScore,
  appearance,
  aroma,
  flavour,
  price,
  sessionability,
  beerStyle,
  country,
  link,
}) => {
  const calculateScoreColour = (score) => {
    if (score > 80) {
      return "#26a32a";
    } else if (score > 60) {
      return "#bbb725";
    } else if (score > 45) {
      return "#bb8625";
    } else {
      return "#bb2525";
    }
  };

  const flagHelper = (country) => {
    if (isoCountries[country]) {
      return countryCodeEmoji(isoCountries[country]);
    } else if (country === "Scotland") {
      return "🏴󠁧󠁢󠁳󠁣󠁴󠁿";
    } else if (country === "Wales") {
      return "🏴󠁧󠁢󠁷󠁬󠁳󠁿";
    } else if (country === "Homebrew") {
      return `🏠🍺`;
    } else {
      return `- ${country}`;
    }
  };

  return (
    <StyledBeer>
      <Top>
        <Name>
          <h2>{name}</h2>
          <span>
            By {brewery} <span className="country">{flagHelper(country)}</span>
          </span>
        </Name>
        <Score color={calculateScoreColour(oysterScore)}>
          <span>Score</span>
          {oysterScore}
        </Score>
      </Top>
      <InfoOuter>
        <Info className="visable">
          <Item>
            <ItemInner>
              <ExtraInfo>
                <FontAwesomeIcon icon={faBeer} size="1x" />
                <TitleScore>Beer Style</TitleScore>
              </ExtraInfo>
              <TheInfo>{beerStyle}</TheInfo>
            </ItemInner>
          </Item>
          <Item>
            <ItemInner>
              <ExtraInfo>
                <FontAwesomeIcon icon={faEye} size="1x" />
                <TitleScore>Appearance</TitleScore>
              </ExtraInfo>
              <TheInfo>{appearance}</TheInfo>
            </ItemInner>
          </Item>
          <Item>
            <ItemInner>
              <ExtraInfo>
                <FontAwesomeIcon icon={faChartBar} size="1x" />
                <TitleScore>Aroma</TitleScore>
              </ExtraInfo>
              <TheInfo>{aroma}</TheInfo>
            </ItemInner>
          </Item>
          <Item>
            <ItemInner>
              <ExtraInfo>
                <FontAwesomeIcon icon={faPoundSign} size="1x" />
                <TitleScore>Price</TitleScore>
              </ExtraInfo>
              <TheInfo>{price}</TheInfo>
            </ItemInner>
          </Item>
          <Item>
            <ItemInner>
              <ExtraInfo>
                <FontAwesomeIcon icon={faRedo} size="1x" />
                <TitleScore>Sessionability</TitleScore>
              </ExtraInfo>
              <TheInfo>{sessionability}</TheInfo>
            </ItemInner>
          </Item>
          <Item>
            <ItemInner>
              <ExtraInfo>
                <FontAwesomeIcon icon={faBeer} size="1x" />
                <TitleScore>Flavour</TitleScore>
              </ExtraInfo>
              <TheInfo>{flavour}</TheInfo>
            </ItemInner>
          </Item>
        </Info>
      </InfoOuter>

      <VideoLink>
        {link && (
          <Button href={link} target="_blank" className="visable">
            Watch the video
          </Button>
        )}
      </VideoLink>
    </StyledBeer>
  );
};

export default BeerHome;
