import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getOptions } from "../../helpers/contentful";
import { Helmet } from "react-helmet";

const Text = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
`;

const Content = styled.div`
  a,
  a:visited {
    color: #007ba3;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
  }
`;

const Items = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const Item = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #2b2b2b;
  margin-top: 20px;
  border-radius: 20px;
  padding-top: 10px;
  border: 2px solid #007ba3;

  width: 100%;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    width: 300px;
  }

  h2 {
    color: white;
  }

  img {
    max-width: 100%;
    max-height: 300px;
  }
`;

const Price = styled.div`
  color: #20a300;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Button = styled.a`
  text-align: center;
  background-color: #dc2a41;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  border: 0;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
`;

const Merch = () => {
  const [content, setContent] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getContent = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields"],
        content_type: "page",
        "fields.slug[in]": "oyster-boys-merchandise",
      });

      if (response.items && response.items.length > 0)
        setContent(response.items[0].fields);
    };

    getContent();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields"],
        content_type: "product",
      });

      if (response.items && response.items.length > 0)
        setProducts(response.items);
    };

    getProducts();
  }, []);

  if (!content) return <></>;

  return (
    <>
      <Helmet>
        <title>Merchandise - Oyster Boys Brewing Co</title>
      </Helmet>
      <Text>{content.title}</Text>
      <Content>
        {documentToReactComponents(content.content, getOptions())}
      </Content>
      <Items>
        {products.length > 0 ? (
          products.map(({ fields }) => (
            <Item key={fields.title}>
              <h2>{fields.title}</h2>
              <Price>£{fields.price}</Price>
              {fields.image && (
                <img src={fields.image.fields.file.url} alt={fields.title} />
              )}
              <Button href={fields.link} target="_blank">
                Buy Now
              </Button>
            </Item>
          ))
        ) : (
          <></>
        )}
      </Items>
    </>
  );
};

export default Merch;
