import React from "react";
import styled from "styled-components";
import Container from "./Container";
import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";

const StyledHeader = styled.div`
  padding: 20px;
  background-color: #007ba3;
  border-bottom: 5px solid #00baf2;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    text-align: left;
  }

  .logo {
    margin: 0 auto;
    img {
      width: 80px;

      @media ${(props) => props.theme.bootstrapBreakpoints.large} {
        width: 120px;
      }
    }
  }
`;

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
`;

const Menu = styled.nav`
  width: calc(100% - 200px);
  text-align: center;
  display: none;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    display: block;
  }

  a {
    padding: 20px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    font-size: 20px;

    &:hover {
      border-bottom: 5px solid #00baf2;
    }

    &.active {
      border-bottom: 5px solid #00baf2;
    }
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <Container>
        <Wrapper>
          <NavLink to="/" className="logo">
            <img src={logo} alt="Oyster Boys" />
          </NavLink>
          <Menu>
            <NavLink to="/" exact>
              Home
            </NavLink>
            <NavLink to="/about">About Us</NavLink>
            <NavLink to="/scoreboard">Scoreboard</NavLink>
            <NavLink to="/recipes">Recipes</NavLink>
            <NavLink to="/videos">Videos</NavLink>
            <NavLink to="/merch">Merch</NavLink>
          </Menu>
        </Wrapper>
      </Container>
    </StyledHeader>
  );
};

export default Header;
