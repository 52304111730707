import React from "react";
import styled from "styled-components";
import Container from "./Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faInstagram,
  faTwitter,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

const StyledFooter = styled.footer`
  padding: 20px;
  background-color: #353535;
  border-top: 5px solid #dc2a41;
`;

const FooterSection = styled.div`
  border-bottom: 1px solid #dc2a41;
  padding-top: 20px;
  padding-bottom: 20px;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    border-right: 1px solid #dc2a41;
    border-bottom: 0;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }

  a {
    margin-right: 15px;
    color: #676767;
  }

  &:last-of-type {
    border: 0;
  }

  &:first-of-type {
    padding-left: 0;
    padding-top: 0;
  }
`;

const Title = styled.h3`
  color: #dc2a41;
  margin-top: 0;
  padding-top: 0;
`;

const Social = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    flex-direction: row;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Wrapper>
          <FooterSection>
            <Title>Oyster Boys Brewing Co</Title>
            <Social>
              <a
                href="https://www.youtube.com/channel/UCW444QxVwn_2a79u7e_mp4A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/oysterboysbrewingco/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://twitter.com/Oysterboysbrew"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a
                href="https://www.facebook.com/oysterboysbrewingco"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </Social>
          </FooterSection>
          <FooterSection>
            <Title>Get in Touch</Title>
            <a href="mailto:hello@oysterboysbrewing.co.uk">
              hello@oysterboysbrewing.co.uk
            </a>
          </FooterSection>
          <FooterSection>
            <Title>Made By</Title>
            <a
              href="https://www.neil-redfern.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Neil Redfern
            </a>
          </FooterSection>
        </Wrapper>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
