import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import axios from "axios";

const Text = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
`;

const Video = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #2b2b2b;
  margin-top: 20px;
  border-radius: 20px;
  padding-top: 10px;
  border: 2px solid #007ba3;

  iframe {
    width: 100%;

    @media ${(props) => props.theme.bootstrapBreakpoints.large} {
      width: 560px;
    }
  }

  h2 {
    color: white;
  }
`;

const Button = styled.button`
  text-align: center;
  background-color: #dc2a41;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  border: 0;
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  color: white;
`;

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [nextPageToken, setNextPageToken] = useState("");

  useEffect(() => {
    axios
      .get("https://api-oysterboysbrewing-co-uk.onrender.com/v1/videos/")
      .then((result) => {
        setVideos(result.data.currentPage);
        setNextPageToken(result.data.nextPageToken);
      });
  }, []);

  const nextPage = () => {
    axios
      .get(
        `https://api-oysterboysbrewing-co-uk.onrender.com/v1/videos/${nextPageToken}`
      )
      .then((result) => {
        setVideos([...videos, ...result.data.currentPage]);
        setNextPageToken(result.data.nextPageToken);
      });
  };

  return (
    <>
      <Helmet>
        <title>Videos - Oyster Boys Brewing Co</title>
      </Helmet>
      <Text>Videos</Text>
      {videos.map((video) => (
        <Video key={video.id}>
          <h2 dangerouslySetInnerHTML={{ __html: video.title }} />
          <p>{video.description}</p>
          <iframe
            title="video"
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${video.id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Video>
      ))}
      {nextPageToken && <Button onClick={nextPage}>View more</Button>}
    </>
  );
};

export default Videos;
