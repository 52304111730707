import React, { useState, useEffect } from "react";
import { createClient } from "contentful";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getOptions } from "../../helpers/contentful";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import Beer from "../Beer";

const Text = styled.h1`
  color: white;
  padding: 0;
  margin: 0;
`;

const BeerList = styled.div`
  width: 100%;
`;

const Content = styled.div`
  a,
  a:visited {
    color: #007ba3;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    max-width: 100%;
  }
`;

const Filter = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
  }

  box-sizing: border-box;
  column-gap: 50px;
`;

const Section = styled.div`
  padding: 20px;
  background-color: #4a4a4a;
  border: 2px solid #989898;
  color: white;
  padding-bottom: 10px;
  margin-bottom: 20px;

  @media ${(props) => props.theme.bootstrapBreakpoints.large} {
    margin-bottom: 0px;
  }

  h3 {
    margin-top: 0;
    color: #00baf2;
  }
`;

const FilterItem = styled.div`
  margin-bottom: 10px;
  display: inline-grid;
  grid-template-columns: 100px 1fr;
  width: 100%;
  align-items: center;
`;

const SelectContainer = styled.div`
  position: relative;

  &:after {
    content: "<>";
    font: 17px "Consolas", monospace;
    color: #333;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 11px;
    /*Adjust for position however you want*/

    top: 18px;
    padding: 0 0 2px;
    border-bottom: 1px solid #999;
    /*left line */

    position: absolute;
    pointer-events: none;
  }

  & select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add some styling */

    display: block;
    width: 100%;
    height: 50px;
    float: right;
    margin: 5px 0px;
    padding: 0px 24px;
    font-size: 16px;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #cccccc;
    -ms-word-break: normal;
    word-break: normal;

    &::-ms-expand {
      display: none;
    }
  }
`;

const NoBeersFound = styled.div`
  font-weight: bold;
  color: #dc2a41;
`;

const Scoreboard = () => {
  const [content, setContent] = useState(null);
  const [beers, setBeers] = useState([]);
  const [filterdBeers, setFilteredBeers] = useState([]);
  const [beerStyles, setBeerStyles] = useState([]);
  const [countries, setCountries] = useState([]);

  const [filterCountry, setFilterCountry] = useState("");
  const [filterBeerStyle, setFilterBeerStyle] = useState("");
  const [orderBy, setOrderBy] = useState("oysterScore");

  useEffect(() => {
    const getContent = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields"],
        content_type: "page",
        "fields.slug[in]": "scoreboard",
      });

      if (response.items && response.items.length > 0)
        setContent(response.items[0].fields);
    };

    getContent();
  }, []);

  useEffect(() => {
    const getBeers = async () => {
      const client = createClient({
        space: "1ihs72m7oqzb",
        accessToken: "OU6r4iRPb1GPEFvcI01GBXnqteHy9ODeqnxoN4NulOU",
      });

      const response = await client.getEntries({
        select: ["fields"],
        content_type: "beer",
      });

      if (response.items && response.items.length > 0) {
        const orderedBeers = response.items.sort(
          (a, b) => b.fields.oysterScore - a.fields.oysterScore
        );
        setBeers(orderedBeers);
        setFilteredBeers(orderedBeers);

        setBeerStyles(
          response.items.map((item) => item.fields.beerStyle).filter(onlyUnique)
        );

        setCountries(
          response.items.map((item) => item.fields.country).filter(onlyUnique)
        );
      }
    };

    getBeers();
  }, []);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const orderByFunc = (e) => {
    const sorted = [...filterdBeers].sort(
      (a, b) => b.fields[e.target.value] - a.fields[e.target.value]
    );

    setOrderBy(e.target.value);
    setFilteredBeers(sorted);
  };

  const filterByCountry = (value) => {
    setFilterCountry(value);

    if (filterBeerStyle !== "") {
      setFilteredBeers(
        beers
          .filter(
            (beer) =>
              (beer.fields.country === value || value === "") &&
              beer.fields.beerStyle === filterBeerStyle
          )
          .sort((a, b) => b.fields[orderBy] - a.fields[orderBy])
      );
    } else {
      setFilteredBeers(
        beers
          .filter((beer) => beer.fields.country === value || value === "")
          .sort((a, b) => b.fields[orderBy] - a.fields[orderBy])
      );
    }
  };

  const filterByBeerStyle = (value) => {
    setFilterBeerStyle(value);

    if (filterCountry !== "") {
      setFilteredBeers(
        beers
          .filter(
            (beer) =>
              (beer.fields.beerStyle === value || value === "") &&
              beer.fields.country === filterCountry
          )
          .sort((a, b) => b.fields[orderBy] - a.fields[orderBy])
      );
    } else {
      setFilteredBeers(
        beers
          .filter((beer) => beer.fields.beerStyle === value || value === "")
          .sort((a, b) => b.fields[orderBy] - a.fields[orderBy])
      );
    }
  };

  if (!content) return <></>;

  return (
    <>
      <Helmet>
        <title>Oyster Boys Brewing Co - Scoreboard</title>
      </Helmet>
      <Text>{content.title}</Text>
      <Content>
        {documentToReactComponents(content.content, getOptions())}
      </Content>

      <Filter>
        <Section>
          <h3>Filter</h3>
          <FilterItem>
            <div>Beer Style: </div>
            <SelectContainer>
              <label>
                <select onChange={(e) => filterByBeerStyle(e.target.value)}>
                  <option value="">All</option>
                  {beerStyles.map((style) => (
                    <option value={style} key={style}>
                      {style}
                    </option>
                  ))}
                </select>
              </label>
            </SelectContainer>
          </FilterItem>
          <FilterItem>
            <div>Country: </div>
            <SelectContainer>
              <label>
                <select onChange={(e) => filterByCountry(e.target.value)}>
                  <option value="">All</option>
                  {countries.map((country) => (
                    <option value={country} key={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </label>
            </SelectContainer>
          </FilterItem>
        </Section>
        <Section>
          <h3>Order By</h3>
          <SelectContainer>
            <label>
              <select onChange={orderByFunc}>
                <option value="oysterScore">Overall Rating</option>
                <option value="appearance">Appearance</option>
                <option value="aroma">Aroma</option>
                <option value="price">Price</option>
                <option value="sessionability">Sessionability</option>
                <option value="flavour">Flavour</option>
              </select>
            </label>
          </SelectContainer>
        </Section>
      </Filter>

      <BeerList>
        {filterdBeers.length > 0 ? (
          filterdBeers.map((beer, i) => {
            return <Beer rating={i + 1} {...beer.fields} key={beer.sys.id} />;
          })
        ) : (
          <NoBeersFound>
            <FontAwesomeIcon icon={faExclamationCircle} size="1x" /> No beers
            have been found with this filter, please try another
          </NoBeersFound>
        )}
      </BeerList>
    </>
  );
};

export default Scoreboard;
